.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  font-size: 2em;
  margin-top: 0px;
  margin-bottom: 5px;
}

h2 {
  margin-top: 5px;
  margin-bottom: 35px;
  font-size: 1em;
  font-weight: lighter;
}

.App-link {
  color: white;
}

.profile-image {
  max-width: 72px;
  border-radius: 50%;
  margin-bottom: 5px;
  margin-top: 5px;
}

.links {
  display: inline-grid;
}

a {
  padding: 7px;
  text-decoration: none;
  font-size: 0.8em;
}
